import {
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useMemo,
  Suspense,
} from "react";
import Link from "next/link";
import { Canvas, extend, useFrame, useThree } from "@react-three/fiber";
import {
  useCursor,
  MeshPortalMaterial,
  useAnimations,
  useGLTF,
  Text,
  useTexture,
  PerspectiveCamera,
  Environment,
} from "@react-three/drei";
import * as THREE from "three";
import { KTX2Loader, SkeletonUtils } from "three-stdlib";

const Anime = ({ clip, ...props }) => {
  const group = useRef();
  const { gl } = useThree();
  const zPlane = new THREE.Plane(new THREE.Vector3(0, 0, 6), 0);
  const yPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 1);
  const ktx2Loader = new KTX2Loader()
    .setTranscoderPath(`/libs/basis/`)
    .detectSupport(gl);
  const URL = `https://d1pifp3pkaokt6.cloudfront.net/bv/tverse/Tverse_Ani03-etc1s_255.glb`;
  const { scene, nodes, materials, animations } = useGLTF(
    URL,
    true,
    false,
    (loader) => {
      loader.setKTX2Loader(ktx2Loader);
      ktx2Loader.dispose();
    }
  );
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);

  const { actions, names } = useAnimations(animations, group);
  useEffect(() => {
    if (actions) {
      actions["BaseCross_center_00"]?.reset().fadeIn(0.5).play();
    }
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <primitive object={clone} />
    </group>
  );
};

const hero = () => {
  return (
    <section className="relative pb-10 pt-20 md:pt-12 h-1527">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
        <img
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full"
        />
      </picture>

      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
            <div className="text-jacarta-700 font-bold font-display mb-6 text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-8xl">
              Let’s create your <div className="inline text-accent">avatar</div>
              !
            </div>
            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              Unleash your imagination and craft a digital reflection of
              yourself with limitless possibilities
            </p>
            <div className="flex space-x-4">
              {/* <Link href="/create">
                <a className="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                  Upload
                </a>
              </Link> */}
              <Link href="https://play.brandverse.world">
                <a className="text-white shadow-accent-volume hover:bg-accent-dark hover:shadow-accent-volume w-36 rounded-full bg-accent py-3 px-8 text-center font-semibold transition-all hover:text-white">
                  Create now!
                </a>
              </Link>
            </div>
          </div>

          {/* <!-- Hero image --> */}
          <div className="col-span-6 xl:col-span-8">
            <div className="relative text-center  md:text-right">
              <img
                src="/images/hero/draft_1.png"
                className=" xl:p-12 pt-0 inline-block w-72  sm:w-full lg:w-[24rem] xl:w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default hero;
