const UnboxElements = () => {
  const mockData = [
    {
      img: "/images/draft_1/icon/mail.png",
      title: "Brief Us",
      desc: "Quick&Easy Submission Respond Within 24 Hours",
    },
    {
      img: "/images/draft_1/icon/chat.png",
      title: "Chat with Sales ",
      desc: "Available Monday to Friday 9 AM to 6 PM (GMT+7)",
    },
    {
      img: "/images/draft_1/icon/call.png",
      title: "Call Sales",
      desc: "Available Monday to Friday  9 AM to 6 PM (GMT+7)",
    },
  ];
  return (
    <>
      <div className="grid md:grid-cols-3 text-center gap-8">
        {mockData.map((item, index) => (
          <div
            key={index}
            className="text-center flex flex-col items-center gap-2"
          >
            <img
              src={item.img}
              alt={item.title}
              className="w-1/5 md:w-1/3 mb-6 "
            />
            <h3 className="text-2xl tracking-wide   text-accent">
              {item.title}
            </h3>
            <h5 className="text-sl  tracking-wide w-1/2 m-auto text-jacarta-600	">
              {item.desc}
            </h5>
          </div>
        ))}
      </div>
    </>
  );
};

export default UnboxElements;
