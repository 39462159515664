const TeamElements = () => {
  const mockData = [
    {
      img: "/images/draft_1/team/1.png",
      name: "Nattasate",
      lastname: "Trithipcharoenchai",
      role: "CEO & Founder, Jenosize ",
    },
    {
      img: "/images/draft_1/team/2.png",
      name: "Tanakorn",
      lastname: "Norsuwan",
      role: "Co-Founder & Engineering Manager",
    },
    {
      img: "/images/draft_1/team/3.png",
      name: "Panupak",
      lastname: "Amarapitak",
      role: "LEAD UX DESIGNER",
    },
    {
      img: "/images/draft_1/team/4.png",
      name: "Suradech",
      lastname: "Jarupramote",
      role: "3D Generalist",
    },
  ];
  return (
    <>
      <div className="grid md:grid-cols-4 grid-cols-2">
        {mockData.map((item, index) => (
          <div key={index} className="text-center ">
            <img src={item.img} alt={item.name} />
            <h3 className="text-2xl tracking-wide leading-3	  ">{item.name}</h3>
            <h3 className="text-2xl tracking-wide mb-2">{item.lastname}</h3>
            <h5 className="uppercase tracking-wide md:w-3/5 w-full m-auto leading-4 text-jacarta-600	">
              {item.role}
            </h5>
          </div>
        ))}
      </div>
    </>
  );
};

export default TeamElements;
