const Partner = ({ header, img }) => {
  return (
    <>
      <div className="text-2xl font-bold text-jacarta-200 tracking-wider mb-8">
        {header}
      </div>
      <div className="flex flex-wrap mb-12">
        {img.map((item, index) => {
          return (
            <div
              className="md:w-[150px] md:h-[150px] w-[70px] h-[70px] p-4 md:p-8 rounded-xl border-jacarta-100 border-2 m-2 flex"
              key={index}
            >
              <img
                src={`/images/draft_1/partner/${item}.png`}
                alt={item}
                className="m-auto md:max-h-14  max-h-8 	 "
              />
            </div>
          );
        })}{" "}
      </div>
    </>
  );
};

export default Partner;
