import Link from "next/link";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";

import { useState } from "react";
const FormComponent = () => {
  return (
    <>
      <section className="overflow-hidden py-24 dark:bg-jacarta-900">
        <div className="container block md:grid md:grid-cols-7 rounded-3xl  md:shadow-roundbase relative">
          <div className="col-span-4 ">
            <img
              src="/images/draft_1/avatar-form.png"
              className=" relative md:absolute bottom-0 left-[-30px] md:w-3/5 w-full"
            />
          </div>
          <div className="relative md:col-start-5 md:col-end-8	">
            <Form />
          </div>
        </div>
      </section>
    </>
  );
};
export default FormComponent;

const Form = () => {
  const ariaLabel = { "aria-label": "description" };

  return (
    <div className="rounded-xl border-jacarta-50 border-2 mt-4 mb-4 p-6 w-9/10 flex  flex-col	 gap-4">
      <img src="/images/logo_blue.png" className="w-3/5" />
      <div className="text-lg bold ">
        “The best choice of Community and Activity metaverse platform”
      </div>
      <div className="text-sm tracking-wide">Find out how you can do it </div>

      <Input placeholder="Full Name" inputProps={ariaLabel} />
      <Input placeholder="Email address" inputProps={ariaLabel} />
      <Input placeholder="Phone Number" inputProps={ariaLabel} />
      <div className="bg-accent hover:bg-blue text-white md:text-base  text-sl p-2 m-3 rounded-3xl text-center tracking-wider">
        Get Your Free Metaverse Consultation
      </div>
      <div className="text-sm">
        This site is protected by reCAPTCHA and the Google{" "}
        <Link href="/privacypolicy">
          <a className="underline underline-offset-1	hover:text-accent hover:text-blue">
            Privacy Policy
          </a>
        </Link>{" "}
        and{" "}
        <Link href="/tarms">
          <a className=" underline underline-offset-1	hover:text-accent hover:text-blue">
            Terms of Service
          </a>
        </Link>{" "}
        apply.
      </div>
    </div>
  );
};
