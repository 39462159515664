import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import ArtData from "../../data/art_data";
import Link from "next/link";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";

const ArtsCarousel = () => {
  const [tverse, setTverse] = useState([]);
  const getTverse = async () => {
    const request = axios({
      url: `https://api-dev.brandverse.world/api/v1/t-verse-land`,
      method: "GET",
      headers: {
        "x-api-key": "F352S5H3es9LMJK9cZcy",
      },
      data: {},
      timeout: 20000,
      dataType: "json",
    });
    await request
      .then(({ data }) => {
        if (data?.code === 0) {
          setTverse(data.data);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  useEffect(() => {
    getTverse();
  }, []);

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView="auto"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {tverse.map((item) => {
          const { id, cover_image_url, title, owner, tag } = item;
          return (
            <SwiperSlide key={id}>
              <article>
                <div className="dark:bg-jacarta-700 rounded-2.5xl block overflow-hidden bg-white shadow-md transition-shadow hover:shadow-lg">
                  <figure className="relative">
                    <Link href={`/verse/${title}`}>
                      <a>
                        <img
                          src={`https://d3gezogt63fd13.cloudfront.net${cover_image_url}`}
                          alt="item 1"
                          height="430"
                          width="379"
                          layout="responsive"
                          className="swiper-lazy h-[430px] w-full object-cover swiper-lazy-loaded rounded-2.5xl"
                        />
                      </a>
                    </Link>
                  </figure>
                  <div className="p-6">
                    <div className="flex">
                      <Link href={`/verse/${title}`}>
                        <a className="shrink-0 mr-4">
                          {/* user image */}
                          {/* <Image
                            src={userImage}
                            alt={name}
                            height={40}
                            width={40}
                            className="mr-4 h-10 w-10 rounded-full"
                          /> */}
                        </a>
                      </Link>
                      <div>
                        <Link href={`/verse/${title}`}>
                          <a className="block">
                            <span className="font-display hover:text-accent text-jacarta-700 text-xl leading-none dark:text-white">
                              {title}
                            </span>
                          </a>
                        </Link>
                        <Link href={`/verse/${title}`}>
                          <a className="text-jacarta-300 text-md">{tag}</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <!-- Slider Navigation --> */}
      <div className="group swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default ArtsCarousel;
