import Image from "next/image";

const OurNumberComponent = () => {
  return (
    <>
      <div className="sm:flex md:grid grid-cols-5 rounded-3xl shadow-roundbase m-auto mb-16 mt-0 md:mt-16 max-h-fit		 w-10/12	relative	">
        <div className="m-auto mt-4 mb-4 col-start-4 col-span-2 p-6 ">
          <div className="text-4xl font-bold mb-4">
            Our numbers Tell <br />
            more about us
          </div>
          <div className="grid grid-cols-2 w-full ">
            <div className="pr-6 border-r-4 border-jacarta-100">
              <div className="sm:text-3xl text-5xl font-bold text-accent">
                3 +
              </div>
              <div className="text-base">Total verse opened with us</div>
            </div>
            <div>
              <div className="pl-6 ">
                <div className="text-5xl sm:text-3xl font-bold text-accent">
                  500,000 +
                </div>
                <div className="text-base">
                  Total active user in last year’s
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 ">
          <img
            src="/images/draft_1/avatars.png"
            alt="avatars"
            className="sm:relative sm:w-full md:absolute bottom-0 h-auto md:w-3/5"
          />
        </div>
      </div>
    </>
  );
};

export default OurNumberComponent;
