import ArtsCarousel from "../carousel/coverflowCarousel_draft1";

const StoriesComponent = () => {
  return (
    <>
      {/* <!-- Partners --> */}
      <section className="overflow-hidden pt-12 md:py-12 dark:bg-jacarta-900">
        <div className="container">
          <div className="mx-auto mb-14 max-w-xl text-center">
            <h2 className="tracking-wider mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              Success Stories
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="relative">
            <ArtsCarousel />
          </div>
        </div>
      </section>

      {/* <!-- end partners -->  */}
    </>
  );
};

export default StoriesComponent;
