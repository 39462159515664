import React from "react";
import Hero from "../../components/hero/hero";
import HeroDraft1 from "../../components/hero/hero_draft_1";
import Meta from "../../components/Meta";
import Download from "../../components/blog/download";
import Partners from "../../components/dao/TrustedPartner";
import Verse from "../../components/dao/Verse";
import Stories from "../../components/dao/Stories";
import Features from "../../components/dao/Features";
import OurNumber from "../../components/dao/OurNumber";
import StrategicPartnerships from "../../components/dao/StrategicPartnerships";
import Team from "../../components/dao/Team";
import Form from "../../components/dao/Form";
import Unbox from "../../components/dao/Unbox";

import * as Actions from "../../store/actions/index";
import { useSelector, useDispatch } from "react-redux";
import { Provider } from "react-redux";
import { makeStore } from "../../store/index";

const Home_1 = () => {
  const store = makeStore();

  return (
    <Provider store={store}>
      <main>
        <Meta title="Home | The best choice of Community and Activity metaverse platform" />
        <HeroDraft1 />
        <Verse />
        <Stories />
        <OurNumber />
        <Features />
        <StrategicPartnerships />
        <Team />
        <Form />
        <Unbox />

        {/* <Testimonial /> */}
        {/* <Partners /> */}
        {/* <Download /> */}
      </main>
    </Provider>
  );
};

export default Home_1;
