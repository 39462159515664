import Unbox from "./UnboxElement";

const TeamComponent = () => {
  return (
    <>
      {" "}
      <section className="overflow-hidden py-12 dark:bg-jacarta-900">
        <div className="container">
          <div className="mx-auto mb-12 max-w-xl text-center">
            <h2 className="tracking-wider mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              Unbox Future Opportunities Together{" "}
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="relative">
            <Unbox />
          </div>
        </div>
      </section>
    </>
  );
};
export default TeamComponent;
