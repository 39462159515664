import Image from "next/image";

const FeaturesElement = () => {
  const mockData = [
    {
      img: "/images/draft_1/feature_1.png",
      title: "Virtual Live Event",
      bullets: [
        "Customer & Employee Engagement",
        "Immersive Experiences",
        "Seamless Integration",
        "Real-Time Collaboration and Feedback",
      ],
    },
    {
      img: "/images/draft_1/feature_2.png",
      title: "Virtual Showroom",
      bullets: [
        "Showroom & Sales Services",
        "Immersive Product Display",
        "Personalized Customer Experience",
        "Global Accessibility and Cost-Efficiency",
      ],
    },
    {
      img: "/images/draft_1/feature_3.png",
      title: "CRM",
      bullets: [
        "Seamless Customer Engagement",
        "Enhanced Personalization",
        "Multi-Platform Integration",
        "Real-Time Collaboration and Support",
      ],
    },
    {
      img: "/images/draft_1/feature_4.png",
      title: "Learning & Entertainment",
      bullets: [
        "Immersive Learning Environments",
        "Personalized & Collaborative Learning Spaces",
        "Virtual Concerts and Performances",
        "Virtual Worlds for Role-Playing Games (RPGs)",
        "Virtual Reality (VR) Theme Parks:",
      ],
    },
    {
      img: "/images/draft_1/feature_5.png",
      title: "Retail 3D Virtual Store",
      bullets: [
        "Shoppertainment",
        "CRM Engagement",
        "Virtual Sales and Lead Generation",
      ],
    },
    {
      img: "/images/draft_1/feature_6.png",
      title: "Admin Dashboard / Reporting",
      bullets: [
        "Build Future Channel for Your Brand",
        "Collab & Connect with T-Verse partner",
        "Web 3.0, NFT and Digital Technology",
        "Data Analytics and Insights",
        "Data-Driven Decision Making",
      ],
    },
  ];
  return (
    <>
      <div className="grid grid-cols-2  md:grid-cols-3 gap-4">
        {mockData.map((item, index) => {
          const { img, title, bullets } = item;

          return (
            <div key={index} className="m-auto mt-0 mb-4">
              <Image src={img} alt={title} height="200" width="300" />
              <div className="text-xl tracking-wide	pl-2 mt-2"> {title} </div>
              <ul className="leading-none tracking-wide	pl-6 mt-2">
                {bullets.map((bullet, index) => {
                  return (
                    <li className="text-jacarta-300 list-disc	" key={index}>
                      {bullet}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FeaturesElement;
