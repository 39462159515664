import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow, Ally } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { coverflow_data } from "../../data/coverflow_data";
import Link from "next/link";
import Image from "next/image";

const CoverflowCarousel = () => {
  const mockData = [
    {
      img: "/images/draft_1/bg_7.png",
      logo: "/images/draft_1/logo_7.png",
      desc: "Jenosize revolutionized our shopping experience with 7Verse. Thanks to their innovative approach, 7Verse quickly attracted thousands of users, making it a game-changer.",
      name: "Varunrat Hiranchatchaval",
      bu: "Digital Marketing, CP ALL Public Co., Ltd.",
    },
    {
      img: "/images/draft_1/bg_7.png",
      logo: "/images/draft_1/logo_7.png",
      desc: "Jenosize revolutionized our shopping experience with 7Verse. Thanks to their innovative approach, 7Verse quickly attracted thousands of users, making it a game-changer.",
      name: "Varunrat Hiranchatchaval",
      bu: "Digital Marketing, CP ALL Public Co., Ltd.",
    },
    {
      img: "/images/draft_1/bg_7.png",
      logo: "/images/draft_1/logo_7.png",
      desc: "Jenosize revolutionized our shopping experience with 7Verse. Thanks to their innovative approach, 7Verse quickly attracted thousands of users, making it a game-changer.",
      name: "Varunrat Hiranchatchaval",
      bu: "Digital Marketing, CP ALL Public Co., Ltd.",
    },
    {
      img: "/images/draft_1/bg_7.png",
      logo: "/images/draft_1/logo_7.png",
      desc: "Jenosize revolutionized our shopping experience with 7Verse. Thanks to their innovative approach, 7Verse quickly attracted thousands of users, making it a game-changer.",
      name: "Varunrat Hiranchatchaval",
      bu: "Digital Marketing, CP ALL Public Co., Ltd.",
    },
  ];
  return (
    <>
      {/* <!-- Coverflow Slider --> */}
      <div className="relative px-6 pb-16 sm:px-0">
        {/* <!-- Slider --> */}
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            100: {
              // width: 640,
              slidesPerView: 1,
            },
            575: {
              // width: 640,
              slidesPerView: 1,
            },
            // when window width is >= 768px
            992: {
              // width: 768,
              slidesPerView: 1,
            },
          }}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={1}
          loop={true}
          coverflowEffect={{
            rotate: 30,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          navigation={{
            nextEl: ".swiper-button-next-4",
            prevEl: ".swiper-button-prev-4",
          }}
          className="swiper coverflow-slider !py-5"
        >
          {mockData.map((item, i) => {
            const { img, logo, desc, name, bu } = item;
            const itemLink = img
              .split("/")
              .slice(-1)
              .toString()
              .replace(".jpg", "")
              .replace(".gif", "")
              .replace("_lg", "");
            return (
              <SwiperSlide key={i}>
                {/* <article> */}
                {/* <div className="block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700 m-10"> */}
                {/* <figure className="relative"> */}
                {/* <Link href={"/item/" + itemLink}>
                      <a> */}
                <div className="relative">
                  {/* <Image */}
                  <img
                    src={img}
                    alt={name}
                    className="swiper-lazy h-full w-full object-cover overflow-hidden rounded md:rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700 "
                    // height="500"
                    // width="1220"
                  />
                  <div className=" xl:top-10 xl:right-10 md:bg-white-7 md:absolute md:top-5 md:right-5 md:w-3/6 md:rounded md:p-6 md:pb-12 md:pt-12 sm:relative w-full p-6 sm:bg-white rounded-b-lg border-jacarta-50  border-2		">
                    <Image
                      src={logo}
                      alt={name}
                      // className="m-4"
                      height="35"
                      width="35"
                    />
                    <div className="md:text-md lg:text-2xl font-medium md:mt-2 sm:leading-3	text-jacarta-900">
                      {desc}
                    </div>
                    <div className="md:pt-4 pt-8 text-jacarta-900">{name}</div>
                    <div className="text-jacarta-base">{bu}</div>
                  </div>
                </div>
                {/* </a>
                    </Link> */}
                {/* </figure> */}
                {/* </div> */}
                {/* </article> */}
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="swiper-button-prev-4 group absolute top-1/2 left-[-72px] transform  translate-y-[-50%] z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
          </svg>
        </div>
        <div className="swiper-button-next-4 group absolute top-1/2 right-[-72px] transform  translate-y-[-50%] z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
          </svg>
        </div>

        {/* <!-- end coverflow slider --> */}
      </div>
    </>
  );
};

export default CoverflowCarousel;
