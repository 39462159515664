import Image from "next/image";
import PartnerComponent from "./Partner";

const StrategicPartnershipsComponent = () => {
  const mockImg1 = [
    "1_7-11",
    "2_Aurora",
    "3_Boxme",
    "4_CPN",
    "5_3+",
    "6_Denso",
    "7_Dharmniti",
    "8_Divana",
    "9_GMM",
    "10_jenosize",
    "11_KBTG",
    "12_KKTT",
    "13_Krungsri",
    "14_Loveis",
    "15_MillCon",
    "16_Tidlor",
    "17_PTT",
    "18_Porto",
    "19_PPPF",
    "20_Sc",
    "21_SCG",
    "22_Thairath",
    "23_TheMall",
    "24_Watsons",
    "25_Zipmex",
  ];
  const mockImg2 = [
    "26_A49",
    "27_Addict",
    "28_EGG",
    "29_FabCafe",
    "30_GameIndy",
    "31_HMB",
    "32_Innova",
    "33_Mainarsam",
    "34_Vithita",
  ];
  const mockImg3 = [
    "35_Botnoi",
    "36_Conicle",
    "37_Datawow",
    "38_Metaverse",
    "39_1Moby",
    "40_RealSmart",
    "41_Token",
    "42_VMWare",
    "43_Yes",
  ];
  const mockImg4 = ["44_NIA", "45_DITP", "46_TU"];
  return (
    <>
      <section className="overflow-hidden py-12 dark:bg-jacarta-900 ">
        <div className="container">
          <div className="mx-auto mb-14 max-w-xl text-center">
            <h2 className="mb-6 mt-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              Strategic partnerships
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="relative">
            <PartnerComponent header="Business Partner" img={mockImg1} />
            <PartnerComponent header="Creator Partner" img={mockImg2} />
            <PartnerComponent header="Tech Partner" img={mockImg3} />
            <PartnerComponent
              header="Government & University Partner"
              img={mockImg4}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default StrategicPartnershipsComponent;
